import React from 'react';
import * as Styled from './slpVancouverImageWithTextStyle';

const SlpVancouverImageWithText = ({ sectionData }) => {
  const DesktopImage = sectionData?.listOfImagesWithPath?.filter(
    (section) => section.type === 'desktopImage'
  )[0];
  const MobileImage = sectionData?.listOfImagesWithPath?.filter(
    (section) => section.type === 'mobileImage'
  )[0];

  if (typeof window === 'undefined') {
    return null;
  } else {
    return (
      <>
        <Styled.BodyContainer>
          <Styled.BodyStyle>
            <Styled.BodyContent>
              <Styled.ImageStyle
                src={DesktopImage?.image?.file?.url}
                alt={DesktopImage?.image?.file?.fileName}
              ></Styled.ImageStyle>
              <Styled.ImageStyleMobile
                src={MobileImage?.image?.file?.url}
                alt={MobileImage?.image?.file?.fileName}
              ></Styled.ImageStyleMobile>
              <Styled.HeaderH1>{sectionData?.header}</Styled.HeaderH1>
              <Styled.ParaText>{sectionData?.description}</Styled.ParaText>
            </Styled.BodyContent>
          </Styled.BodyStyle>
        </Styled.BodyContainer>
      </>
    );
  };
};

export default SlpVancouverImageWithText;
