import styled from 'styled-components';

export const BodyContainer = styled.div`
  background: #fff;
  color: #6e2b8b;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const BodyStyle = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 10px;
  }
`;

export const BodyContent = styled.div`
  width: 650px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px 35px 0 35px;
  }
`;

export const ImageStyle = styled.img`
  width: 100%;
  height: auto;
  border: 0;
  vertical-align: top;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const ImageStyleMobile = styled.img`
  display: none;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 350px;
    height: auto;
    display: block;
    padding-left: 40px;
    padding-right: 40px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

export const HeaderH1 = styled.h1`
  font-size: 25px;
  color: #6e2b8b;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
`;

export const ParaText = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #666;
  margin: 0 0 20px 0;
  padding: 0;
`;
